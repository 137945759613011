import Vue from 'vue'
import VueRouter from 'vue-router'
import Familias from '../views/Familias.vue'
import Familia from '../views/Familia.vue'
import Componente from '../views/Componente.vue'
import Relatorio from '../views/Relatorio.vue'
import Register from '../views/Register.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Login from '../views/Login.vue'
import Layout from '../views/layout/Layout.vue'
import Apresentacoes from '../views/Apresentacoes.vue'
import Trend from '../views/Trend.vue'
import Usuarios from '../views/usuario/Usuarios.vue'
import Departamentos from '../views/usuario/Departamentos.vue'
import Notificacoes from '../views/Notificacoes.vue'
import MinhaArea from '../views/usuario/MinhaArea.vue'
import Turnos from '../views/usuario/Turnos.vue'
import Linhas from '../views/usuario/Linhas.vue'
import FechamentoLinhas from '../views/FechamentoLinhas.vue'

import store from '../store/'

import Home from '../views/Home.vue'
import SincronizarControles from '@/views/relatorio/SincronizarControles.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: 'familias',
    component: Layout,
    children: [
      {
        path: '/familias',
        name: 'Familias',
        component: Familias
      },
      {
        path: '/familia/:id',
        name: 'Familia',
        component: Familia
      },
      {
        path: '/componente/:id/:relatorio?',
        name: 'Componente',
        component: Componente
      },
      {
        path: '/relatorio/:id',
        name: 'Relatorio',
        component: Relatorio
      },
      {
        path: '/apresentacoes',
        name: 'Apresentacoes',
        component: Apresentacoes
      },
      {
        path: '/trend',
        name: 'Trend',
        component: Trend
      },
      {
        path: '/usuarios',
        name: 'Usuarios',
        component: Usuarios
      },
      {
        path: '/departamentos',
        name: 'Departamentos',
        component: Departamentos
      },
      {
        path: '/turnos',
        name: 'Turnos',
        component: Turnos
      },
      {
        path: '/linhas',
        name: 'Linhas',
        component: Linhas
      },
      {
        path: '/analise-linhas',
        name: 'AnaliseLinhas',
        component: FechamentoLinhas
      },
      {
        path: '/notificacoes',
        name: 'Notificacoes',
        component: Notificacoes
      },
      {
        path: '/minha_area',
        name: 'MinhaArea',
        component: MinhaArea
      },
      {
        path: 'atualizar-controles',
        name: 'AtualizarControles',
        component: SincronizarControles
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    props: route => ({ token: route.query.token })
  },
  {
    path: '/site',
    name: 'Site',
    component: Home,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: async function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    const findEl = async (hash, x = 0) => {
      return (
        document.querySelector(hash) ||
        new Promise(resolve => {
          if (x > 50) {
            return resolve(document.querySelector("#app"));
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1));
          }, 100);
        })
      );
    };

    if (to.hash) {
      let el = await findEl(to.hash);
      if ("scrollBehavior" in document.documentElement.style) {
        return window.scrollTo({ top: el.offsetTop - 80, behavior: "smooth" });
      } else {
        return window.scrollTo(0, el.offsetTop);
      }
    }

    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/forgot-password', '/reset-password', '/site'];
  const authRequired = !publicPages.includes(to.path);

  const loggedIn = store.state.auth.status.loggedIn;

  if (authRequired && !loggedIn) {

    if(store.state.projectType == 'web'){
      next('/site');
    }else{
      next('/login');
    }    

  } else {
    if (loggedIn) {

      if(store.state.projectType == 'web'){
        const auth = store.state.auth.user;
        const statusSubscription = auth.user.organizacao.responsavel.status_subscription;
        const prazoTrial = auth.user.organizacao.responsavel.prazo_trial;

        if (auth && (prazoTrial == 0 && !statusSubscription) && (to.path != '/minha_area') && authRequired) {
          next('/minha_area');
        }
      }

      if(to.path == '/login'){
        next('/familias');
      }
    }

    next();
  }
});

export default router
