<template>
  <div class="home pt-3">
    <b-breadcrumb>
      <b-breadcrumb-item active class="align-middle">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        {{ $t("breadcrumb.sincronizarControles") }}
      </b-breadcrumb-item>

      <div class="ml-auto" size="sm">
        {{ $t("fechamento-linhas.proxima-atualizacao") }}
        {{ formattedTime }}
        <b-button
          size="sm"
          class="ml-2"
          variant="secondary"
          v-b-tooltip.hover
          :title="$t('fechamento-linhas.atualizar-agora')"
          @click="sincronizarControles()"
        >
          <b-icon icon="arrow-clockwise"></b-icon>
        </b-button>
      </div>
    </b-breadcrumb>

    <b-card class="mt-3" no-body>
      <b-table
        :items="relatorios"
        :fields="fields"
        responsive="sm"
        head-variant="light"
        sort-icon-left
        striped
        :current-page="currentPage"
        :per-page="perPage"
        hover
        show-empty
        class="mb-0"
        :emptyText="$t('global.table-empty')"
      >
      </b-table>
    </b-card>
    <b-row align-h="center" class="my-3">
      <b-col sm="5" md="2" class="my-1">
        <b-form-group>
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          >
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="2" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="relatorios.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <modal-carregando-geral />
  </div>
</template>

<script>
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
export default {
  name: "SincronizarControles",
  components: {
    ModalCarregandoGeral,
  },
  data() {
    return {
      carregando: false,
      timeLeft: 300,
      interval: null,
      relatorios: [],
      fields: [
        { key: "linha", sortable: true },
        { key: "componente", sortable: true },
        { key: "famila", sortable: true },
        { key: "relatorio", sortable: true },
        {
          key: "atualizado",
          label: this.$t("table.title.atualizado-em"),
          sortable: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [
        { value: 5, text: this.$t("paginacao.5-itens-por-pagina") },
        { value: 10, text: this.$t("paginacao.10-itens-por-pagina") },
        { value: 15, text: this.$t("paginacao.15-itens-por-pagina") },
        { value: 100, text: this.$t("paginacao.100-itens-por-pagina") },
      ],
    };
  },
  methods: {
    carregarRelatorios() {
      if (this.carregando == false) {
        this.carregando = true;
        this.$bvModal.show("modal-carregando-geral");
        this.$http
          .get("api/sincronizar-controles/listar-relatorios")
          .then((response) => {
            this.relatorios = [];
            response.data.forEach((relatorio) => {
              const d = new Date(
                relatorio.ultima_sincronizacao.updated_at
              ).toLocaleDateString("pt-br");
              const h = new Date(
                relatorio.ultima_sincronizacao.updated_at
              ).toLocaleTimeString("pt-br");
              this.relatorios.push({
                id: relatorio.id,
                relatorio: relatorio.descricao,
                famila: relatorio.componente.familia.descricao,
                componente: relatorio.componente.descricao,
                linha: relatorio.linha.descricao,
                atualizado: d + " - " + h,
              });
            });
            this.carregando = false;
            this.$bvModal.hide("modal-carregando-geral");
          })
          .catch((errors) => {
            console.log(errors);
            this.carregando = false;
            this.$bvModal.hide("modal-carregando-geral");
          });
      }
    },
    sincronizarControles() {
      if (this.carregando == false) {
        this.carregando = true;
        this.$bvModal.show("modal-carregando-geral");
        this.$http
          .get("api/sincronizar-controles")
          .then((response) => {
            clearInterval(this.interval);
            this.timeLeft = 300;
            this.atualizarDados();
            this.carregando = false;            
            this.carregarRelatorios();            
            console.log(response.data);
          })
          .catch((errors) => {
            this.carregando = false;
            this.$bvModal.hide("modal-carregando-geral");
            console.log(errors);
          });
      }
    },

    atualizarDados() {        
      this.interval = setInterval(() => {
        this.timeLeft--;
        if (this.timeLeft === 0) {
          clearInterval(this.interval);
          this.sincronizarControles();
        }
      }, 1000);
    },
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = this.timeLeft % 60;
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {    
    this.sincronizarControles();
    this.atualizarDados();
  },
};
</script>
